.backImg {
  height: 450px;
  width: 720px;
  background-size: cover;
  background-color: "white";
  background-image: url("../assets/cardEmpR.png");
}
.backImgVerso {
  height: 450px;
  width: 720px;

  background-size: cover;
  background-color: "white";
  background-image: url("../assets/cardEmpV.png");
  /* background-image: url("../assets/cardVerso.png"); */
}

.logoCard {
  /* transform: translate(0px); */
  /* transform: translate(8px, 509px); */
}

.imgCard {
  width: 155px;
  left: 50%;
  /* transform: translate(281px, 546.2px); */
  transform: translate(283.5px, 33px);
}
.matricule {
  transform: translate(52px, -63px);
  /* transform: translate(53.5px, 445.5px); */
}

.inscription {
  /* transform: translate(-55px, 411px); */
  transform: translate(-55px, -99px);
}
.dateCard {
  transform: translate(-8px, 0px);
}
.nameLawyer {
  color: #16a94e;
  transform: translate(0%, -32px);
  /* transform: translate(0%, 474px); */
}
.avocat {
  transform: translate(0%, -32px);
}
.emission {
  transform: translate(47px, 213px);
}
.expiration {
  transform: translate(-45px, 177px);
}
.qrCodeBox {
  transform: translate(0px, 63px);
}
.noteFr {
  transform: translate(47px, 75px);
}
.noteEn {
  /* margin-top: 15px; */
  /* transform: translate(144px, 42px); */
  transform: translate(38px, 75px);
}
.sign {
  transform: translate(439px, -107px);
}
.telCard {
  transform: translate(-120px, 0px);
}
.signName {
}
